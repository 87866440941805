<template>
  <div class="text-left py-8 px-4 px-md-12 grey lighten-3">
    <v-responsive width="100%" max-width="1200" class="overflow-visible mx-lg-auto">
      <h5 class="text-h5 primary--text mb-12">CONHEÇA ALGUNS BENEFÍCIOS DO SISCONTROL:</h5>

      <swiper :options="swiperOption" class="pa-2">
        <swiper-slide v-for="(item, idx) in items" :key="idx" class="slide elevation-3">
          <v-responsive class="img" :aspect-ratio="270 / 219">
            <img :src="require(`@/assets/siscontrol/${item.img}`)" alt="" srcset="" />
          </v-responsive>
          <div class="text-container">
            <p class="body-2 grey--text text--darken-1 text-center">{{ item.text }}</p>
          </div>
        </swiper-slide>
        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      </swiper>
      <div class="pagination-actions mt-4">
        <div class="pag-prev">
          <v-icon size="30" class="pa-0 ma-0">mdi-chevron-left</v-icon>
        </div>
        <div class="pag-next">
          <v-icon size="30" class="pa-0 ma-0">mdi-chevron-right</v-icon>
        </div>
      </div>
    </v-responsive>
  </div>
</template>

<script>
// https://github.surmon.me/vue-awesome-swiper/
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      items: [
        {
          img: 'chunks-1.jpg',
          text: 'compatibilidade com todos os modelos de catracas e portas.',
        },
        {
          img: 'chunks-2.jpg',
          text: 'Integração com elevadores.',
        },
        {
          img: 'chunks-3.jpg',
          text: 'Controle de horários de entrada para certos grupos de usuários.',
        },
        {
          img: 'chunks-4.jpg',
          text: 'Geração de relatórios.',
        },
        {
          img: 'chunks-5.jpg',
          text: 'Inúmeras formas de acesso (cartão, biometria, qrcode, código de barras).',
        },
        {
          img: 'chunks-6.jpg',
          text: 'Integração com os Apps.',
        },
        {
          img: 'chunks-7.jpg',
          text:
            'Controle no fluxo de passagem dos usuários (com serviços como relatórios, configurações nas liberdades de acesso).',
        },
        {
          img: 'chunks-8.jpg',
          text:
            'Drástica redução nos custos de manutenção e atendimento para clientes que já possuem controle de acesso.',
        },
        {
          img: 'chunks-9.jpg',
          text:
            'Segurança e controle de custos: nosso atendimento é ilimitado e tem preço fechado, garantido pela mensalidade preventiva.',
        },
        {
          img: 'chunks-10.jpg',
          text:
            'Inovações constantes e exclusivas por parte da equipe de desenvolvimento da Pisco Sistemas. Boa parte inclusa com o pacote de manutenção preventiva.',
        },
        {
          img: 'chunks-11.jpg',
          text:
            'Consultoria na implementação do projeto, para sempre haver a escolha dos equipamentos de melhor custo benefício do mercado.',
        },
        {
          img: 'chunks-12.jpg',
          text:
            'Possiblidade de consultoria de arquitetura caso o cliente deseje um projeto mais harmônico com o ambiente.',
        },
      ],
    }
  },
  computed: {
    swiperOption() {
      return {
        centeredSlides: false,
        loop: true,
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.pag-next',
          prevEl: '.pag-prev',
        },
        breakpoints: {
          960: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
          600: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          530: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.slide {
  padding: 0px;
  font-size: 0px;
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  min-height: 340px;

  .img {
    display: block;
    position: relative;
    background-color: transparent;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    img {
      display: block;
      position: absolute;
      width: 102%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-in-out;
    }
  }

  &:hover {
    .img {
      background-color: #000;
      img {
        width: 111%;
        opacity: 0.8;
      }
    }
  }

  .text-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 6px;
    flex: 0 0 auto;
  }
}

.pagination-actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;

  .pag-prev,
  .pag-next {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0px;
    outline: none;
    border: 2px solid #c4c4c4;
    border-radius: 7px;
    margin: 6px;
    transition: all 0.3s ease-in-out;

    .v-icon::before {
      transition: all 0.3s ease-in-out;
      color: #c4c4c4 !important;
    }

    &:hover {
      border-color: var(--v-accent-base);

      .v-icon::before {
        color: var(--v-accent-base) !important;
      }
    }
  }
}
</style>
