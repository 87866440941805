<template>
  <v-responsive width="100%" max-width="1200px" class="mx-auto pa-4 pa-sm-6 pa-md-8 text-center">
    <div class="text-h4 text-center ma-0 primary--text mb-6">
      CONHEÇA OS MÓDULOS DO <span class="font-weight-bold accent--text">SISCONTROL:</span>
    </div>
    <div class="body-1 pa-0 mb-8 text--disabled">
      O SisControl possui arquitetura modular, que permite adaptar sua utilização a diferentes
      formas de necessidades, desde uma instalação simples de balcão de portaria até um sofisticado
      controle multi-função, controlando linhas de catracas, portas, portões e cancelas.
    </div>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6" md="4" v-for="(b, i) in beneficios" :key="i">
        <v-responsive
          data-aos="fade-up"
          :data-aos-once="true"
          :data-aos-delay="i * factor"
          width="100%"
          :aspect-ratio="1 / 1"
          class="content pa-0"
        >
          <div class="spacer">spacer</div>

          <div class="text-container grey lighten-3 rounded-lg elevation-2 pa-4">
            <div class="spacer">spacer</div>
            <div class="text-h6 primary--text mb-2">{{ b.titulo }}</div>
            <p class="body-1 grey--text text--darken-1">{{ b.text }}</p>
          </div>
          <div class="img-container accent elevation-4">
            <img :src="require(`@/assets/siscontrol/${b.icon}.png`)" alt="" />
          </div>
        </v-responsive>
      </v-col>
    </v-row>
  </v-responsive>
</template>

<script>
export default {
  name: 'beneficios',
  data() {
    return {
      factor: this.$vuetify.breakpoint.smAndDown ? 0 : 250,
      beneficios: [
        {
          titulo: 'CADASTRAMENTO',
          text:
            'Com uma interface amigável, ágil e intuitiva, o módulo de cadastros possui um método ergonométrico único.',
          icon: 'icon-1',
        },
        {
          titulo: 'ADMINISTRATIVO',
          text:
            'Além de permitir consultas e o gerenciamento de toda plataforma de usuários é possível levantar informações em tempo real, importar e exportar para banco de dados externo, bem como gerar relatórios completos e personalizados.',
          icon: 'icon-2',
        },
        {
          titulo: 'SERVIDOR DE LICENÇAS',
          text:
            'Módulo utilizado para fazer o gerenciamento das máquinas que terão acesso ao Siscontrol, facilitando o controle de qual estação pode ter acesso a qual tipo de operação.',
          icon: 'icon-3',
        },
        {
          titulo: 'MÓDULO BIOMÉTRICO',
          text:
            'Utilizado para habilitar a utilização da verificação de identidade por biometria, configurar alguns parâmetros de segurança quanto à verificação das informações recebidas, como nível de precisão e velocidade.',
          icon: 'icon-4',
        },
        {
          titulo: 'HARDWARE',
          text:
            'Através deste módulo é possível se comunicar e configurar regras com cada um dos periféricos de bloqueio. Como tempo de espera, definição de passagem, temporização e intervalo de espera, dentre outros.',
          icon: 'icon-5',
        },
        {
          titulo: 'MANUTENÇÃO',
          text:
            'Módulo interno, que faz a comunicação entre nossa equipe de manutenção e nossos clientes. A partir desse módulo é possível fazer a manutenção interna de nosso sistema remotamente, sem necessidade de abrir chamados demorados.',
          icon: 'icon-6',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  overflow: visible;

  .spacer {
    display: block;
    width: 100%;
    padding-top: 15%;
    font-size: 0px;
    flex: 0 0 auto;
  }

  .text-container {
    display: block;
    flex: 0 0 auto;
    position: absolute;
    top: 15%;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .img-container {
    display: block;
    position: absolute;
    border-radius: 50%;
    width: 30%;
    padding-top: 30%;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    flex: 0 0 auto;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
