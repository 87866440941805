<template>
  <v-container fluid class="pa-0 ma-0">
    <conheca></conheca>
    <facil></facil>
    <modulos></modulos>
    <beneficios></beneficios>
    <implementacao></implementacao>
    <suporte></suporte>
  </v-container>
</template>

<script>
import Beneficios from '@/components/siscontrol/Beneficios.vue'
import Conheca from '@/components/siscontrol/Conheca.vue'
import Facil from '@/components/siscontrol/Facil.vue'
import Implementacao from '@/components/siscontrol/Implementacao.vue'
import Modulos from '@/components/siscontrol/Modulos.vue'
import Suporte from '@/components/siscontrol/Suporte.vue'

export default {
  components: {
    Beneficios,
    Conheca,
    Facil,
    Implementacao,
    Modulos,
    Suporte,
  },
}
</script>
