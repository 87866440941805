<template>
  <div class="pa-8 pa-md-12 ">
    <v-responsive width="100%" max-width="1200px" class="mx-auto">
      <v-row align="stretch" align-content="stretch" no-gutters>
        <v-col cols="12" md="6" class="pr-0 pr-md-8">
          <h4 class="text-h4 font-weight-bold primary--text mb-8">Suporte Pisco</h4>
          <v-row align="center" justify="space-between" no-gutters>
            <v-col cols="6">
              <v-responsive width="92%" :aspect-ratio="270 / 152" class="img-1"></v-responsive>
            </v-col>

            <v-col cols="6">
              <v-responsive width="92%" :aspect-ratio="270 / 152" class="img-2"></v-responsive>
            </v-col>
          </v-row>

          <p class="pt-8 grey--text">
            Atendimento veloz (boa parte das empresas demora mais de 48h para realizar atendimentos
            presenciais em clientes).
          </p>

          <p class="pt-2 grey--text">
            O <strong>SisControl</strong> consegue gerar relatórios diagnósticos para nossa equipe
            de técnicos, assim é possível entender ou ter dicas de quais podem ser os problemas
            ocorrendo a partir dá própria empresa. Assim, conseguimos, inúmeras vezes, resolver os
            problemas sem precisar ir pessoalmente ao cliente.
          </p>
        </v-col>

        <v-col cols="12" md="6">
          <v-row align="start" justify="center" class="fill-height">
            <!-- ####################### -->
            <!-- ### EXPANSION PANEL ### -->
            <!-- ####################### -->
            <v-expansion-panels
              v-model="expandModel"
              mandatory
              flat
              class="rounded-0 sc-expansion-panel"
            >
              <!-- ############################## -->
              <!-- ### PANEL: SISCONTROL MONO ### -->
              <!-- ############################## -->
              <v-expansion-panel class="rounded-0">
                <v-expansion-panel-header
                  :expand-icon="expandModel === 0 ? 'mdi-minus' : 'mdi-plus'"
                  disable-icon-rotate
                  class="accent white--text text-h6 font-weigth-bold"
                  >Conheça também o SisControl Mono</v-expansion-panel-header
                >
                <v-expansion-panel-content class="accent white--text pa-0">
                  <p class="px-8 py-0">
                    Forma simples de utilização da nossa solução de controle de acesso.
                  </p>
                  <ul class="px-8 pb-4">
                    <li>Sem barreiras tipo catracas;</li>
                    <li>
                      Módulo focado somente no registro dos usuários que frequentam o edifício;
                    </li>
                    <li>Possui baixo custo;</li>
                    <li>Atendimento remoto ilimitado.</li>
                  </ul>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ########################### -->
              <!-- ### PANEL: MATRICULANDO ### -->
              <!-- ########################### -->
              <v-expansion-panel class="rounded-0">
                <v-expansion-panel-header
                  :expand-icon="expandModel === 1 ? 'mdi-minus' : 'mdi-plus'"
                  disable-icon-rotate
                  class="accent white--text text-h6 font-weigth-bold"
                >
                  Matriculando <br />
                  Software para o gerenciamento de cadastros
                </v-expansion-panel-header>
                <v-expansion-panel-content class="accent white--text pa-0">
                  <p class="px-8 pY-0">
                    Utilizado para o requerimento de novos cartões com artes personalizadas.
                  </p>
                  <p class="px-8 py-0">
                    Possui algumas funcionalidades interessantes para escolas:
                  </p>
                  <ul class="px-8 pb-4">
                    <li>Impressões de listas de chamadas com carômetro;</li>
                    <li>Impressões de etiquetas;</li>
                    <li>Tela para acompanhamento dos pedidos;</li>
                    <li>Dentre outros.</li>
                  </ul>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- FIM EXPANSION PANELS -->
            </v-expansion-panels>
          </v-row>
        </v-col>
      </v-row>
    </v-responsive>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expandModel: 0,
    }
  },
  watch: {
    expandModel(val) {
      console.log(val)
    },
  },
}
</script>

<style lang="scss" scoped>
.img-1 {
  background-image: url('~@/assets/siscontrol/image-4A.jpg');
}

.img-2 {
  background-image: url('~@/assets/siscontrol/image-4B.jpg');
}

.img-1,
.img-2 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>

<style lang="scss">
.sc-expansion-panel {
  .v-expansion-panel-header__icon {
    background-color: #2d3882;
    border-radius: 50%;

    i::before {
      color: white;
    }
  }
}
</style>
